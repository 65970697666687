import { inject } from '@angular/core';
import {
	CustomCookieService,
	LoginData,
	updateAuthState,
} from '@uc/shared/authentication';
import { timeToComplete } from '@uc/utilities';
import { CookieService } from 'ngx-cookie-service';
import { ConversionEventsService } from '../analytics/conversions/conversion-events.service';
import { LoginGTMService } from '../analytics/gtm-events';
import { Store } from '@ngrx/store';

export class LoginResponseHandler {
	private _conversionEventsSrv = inject(ConversionEventsService);
	private _loginGTMSrv = inject(LoginGTMService);
	private _cookie = inject(CookieService);
	private _customCookieSrv = inject(CustomCookieService);
	private _store = inject(Store);

	handleUserResponse(response: LoginData, email: string, initialTime: number) {
		this._setCookies(response, email);
		this._customCookieSrv.initialiseCookies();

		const seconds = timeToComplete(initialTime);
		// GTM event
		this._loginGTMSrv.loginSuccess(+response.user_id, seconds);
		// Google Ads Conversion
		this._conversionEventsSrv.login();

		if (response.role) {
			this._store.dispatch(
				updateAuthState({
					authData: { role: response.role, userId: response.user_id },
				}),
			);
		}
	}

	handleClientResponse(response: LoginData) {
		this._setCookies(response);
		this._customCookieSrv.initialiseCookies();

		if (response.role) {
			this._store.dispatch(
				updateAuthState({
					authData: { role: response.role, userId: response.user_id },
				}),
			);
		}

		location.href = `/dashboard`;
	}

	private _setCookies(response: LoginData, email?: string) {
		for (const [key, value] of Object.entries(response)) {
			const cookieName = `uc-${key}`;
			this._cookie.set(cookieName, value, { path: '/' });
		}

		if (email) {
			this._cookie.set('uc-email', email, { path: '/' });
		}
	}
}
